import React from 'react';
import Button from 'components/Button/Button';
import styles from './HeaderButton.module.scss';

const HeaderButton = ({ onToggle, badge, icon }) => {
    return (
        <div className={styles.wrapper}>
            <Button
                style={styles.button}
                onClick={onToggle}
                children={badge ? <div className={styles.badge}>{badge}</div> : null}
                icon={icon}
                small
                iconOnly />
        </div>
    );
};

export default HeaderButton;

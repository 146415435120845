export const PAGES = {
    'WELCOME': '/',
    'LOGIN': '/login',
    'REGISTRATION': '/registration',
    'RECOVERY': '/recovery',
    'CONFIRM_RECOVERY': '/confirm-recovery',
    'AUTH_MAGIC': '/auth-magic',
    'INVITATION': '/invite',
    'ONE_TIME_CODE': '/one-time-code',
    'ACCOUNT_DELETED': '/account-deleted',
    'QUESTIONS': '/questions',
    'ONBOARDING': '/onboarding',
    'LAST_STEP': '/last-step',
    'CONFIRMED': '/confirmed',
    'PROFILE': '/profile',
    'MY_COMMUNITIES': '/my_communities',
    'CONTACTS': '/contacts',
    'PARTICIPANTS': '/participants',
    'EVENT': '/admin',
    'NOTIFICATIONS': '/notifications',
    'SCORE_MATCH': '/score-match',
    'SUBSCRIPTIONS': '/subscriptions',
    'NOT_FOUND_PAGE': '/not-found',
    'FULL_PARTICIPANT_VIEW': '/u/:slug',
    'PRICING': 'https://volee.co/price',
    'PRIVATE_POLICY': 'https://policy.volee.space/docs/policy_ru.html',
    'PRIVATE_POLICY_EN': 'https://policy.volee.co/docs/policy_en.html',
    'PRIVATE_POLICY_JO': 'https://policy.joinee.io/docs/policy_jo.html',
    'PRIVATE_POLICY_ANNAMONEY': 'https://policy.volee.co/docs/policy_annamoney.html',
    'TERMS_OF_USE': 'https://policy.volee.space/docs/terms_ru.html',
    'TERMS_OF_USE_EN': 'https://policy.volee.co/docs/terms_en.html',
    'TERMS_OF_USE_JO': 'https://policy.joinee.io/docs/terms_jo.html',
    'AGREEMENT_RU': '/docs/agreement_ru.html',
    'AGREEMENT_NW': '/docs/agreement_nw.html',
}

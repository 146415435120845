import React from 'react';
import LangService from "../../services/LangService";
import styles from "./Loader.module.scss";

const Loader = ({ label }) => {
    const langMap = LangService.map.components.loader;
    return (
        <div className={styles.loader}>
            {label || langMap.text}
        </div>
    );
};

export default Loader;
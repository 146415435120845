import React, { Suspense, lazy } from 'react';
import {
    Switch,
    Route, Redirect,
} from "react-router-dom";
import {
    getFirstLogin,
    getToken, isAdmin,
} from "../services/auth";
import { PAGES } from "./PAGES";
import Login from '../pages/Auth/Login';
import Registration from '../pages/Auth/Registration';
import Layout from "../components/Layout/Layout";
import Loader from 'components/Loader/Loader';
import { useCommunity } from "../context/community";
import { useServerProperties } from 'services/server-properties';
import Skeleton from 'components/Skeleton/Skeleton';

// Auth section
const Welcome = lazy(() => import('../pages/Welcome/Welcome'));
const Recovery = lazy(() => import('../pages/Auth/Recovery'));
const ConfirmRecovery = lazy(() => import('../pages/Auth/ConfirmRecovery'));
const Confirmed = lazy(() => import('../pages/Confirmed/Confirmed'));
const OneTimeCode = lazy(() => import('../pages/Auth/OneTimeCode/OneTimeCode'));
const TermsModal = lazy(() => import('../pages/Auth/TermsModal'));
const AuthMagic = lazy(() => import('../pages/Auth/AuthMagic'));
const Invitation = lazy(() => import('../pages/Auth/Invitation'));
const AccountDeleted = lazy(() => import('../pages/AccountDeleted/AccountDeleted'));

// Admin section
const Event = lazy(() => import('../pages/Event/Event'));
const ScoreMatch = lazy(() => import('../pages/ScoreMatch/ScoreMatch'));
const AdminNotifications = lazy(() => import('../pages/AdminNotifications/AdminNotifications'));

// User section
const Questions = lazy(() => import('../pages/Questions/Questions'));
const Onboarding = lazy(() => import('../pages/Onboarding/Onboarding'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const ContactList = lazy(() => import('../pages/ContactList/ContactList'));
const Participants = lazy(() => import('../pages/Participants/Participants'));
const LastStep = lazy(() => import('../pages/LastStep/LastStep'));
const MyCommunities = lazy(() => import('../pages/MyCommunities/MyCommunities'));
const Subscriptions = lazy(() => import('../pages/Subscriptions/Subscriptions'));
const FullParticipantView = lazy(() => import('../pages/FullParticipantView/FullParticipantView'));

const Page = () => {
    const token = getToken();
    const firstLogin = getFirstLogin();
    const { docs } = useServerProperties();
    const { community: { domain, lang } } = useCommunity();

    if (!token) {
        return (
            <Layout>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route path={PAGES.WELCOME} exact component={Welcome} />
                        <Route path={PAGES.LOGIN} component={Login} />
                        <Route path={PAGES.REGISTRATION} component={Registration} />
                        <Route path={PAGES.RECOVERY} component={Recovery} />
                        <Route path={PAGES.CONFIRM_RECOVERY} component={ConfirmRecovery} />
                        <Route path={PAGES.ACCOUNT_DELETED} component={AccountDeleted} />
                        <Route path={PAGES.CONFIRMED} component={Confirmed} />
                        <Route path={PAGES.AUTH_MAGIC} component={AuthMagic} />
                        <Route path={PAGES.INVITATION} component={Invitation} />
                        <Route path={PAGES.ONE_TIME_CODE} component={OneTimeCode} />
                        <Route path={PAGES.FULL_PARTICIPANT_VIEW}>
                            <Suspense fallback={<Skeleton page={PAGES.FULL_PARTICIPANT_VIEW} useContainer />}>
                                <FullParticipantView />
                            </Suspense>
                        </Route>
                        <Redirect to={PAGES.WELCOME} />
                    </Switch>
                </Suspense>
            </Layout>
        )
    }

    if (false && domain && domain !== 'multicarta' && firstLogin && docs.showAgreement) {
        return (
            <Layout>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route component={TermsModal} />
                    </Switch>
                </Suspense>
            </Layout>
        )
    }

    if (isAdmin()) {
        return (
            <Layout>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route path={PAGES.EVENT} component={Event} />
                        <Route path={PAGES.AUTH_MAGIC} component={AuthMagic} />
                        <Route path={PAGES.NOTIFICATIONS} component={AdminNotifications} />
                        <Route path={PAGES.SCORE_MATCH} component={ScoreMatch} />
                        <Redirect to={PAGES.EVENT} />
                    </Switch>
                </Suspense>
            </Layout>
        )
    }

    return (
        <Layout>
            <Switch>
                <Route path={PAGES.QUESTIONS}>
                    <Suspense fallback={<Skeleton page={PAGES.QUESTIONS} useContainer />}>
                        <Questions />
                    </Suspense>
                </Route>
                <Route path={PAGES.ONBOARDING}>
                    <Suspense fallback={<Skeleton page={PAGES.ONBOARDING} useContainer />}>
                        <Onboarding />
                    </Suspense>
                </Route>
                <Route path={PAGES.LAST_STEP}>
                    <Suspense fallback={<Loader />}>
                        <LastStep />
                    </Suspense>
                </Route>
                <Route path={PAGES.CONFIRMED}>
                    <Suspense fallback={<Loader />}>
                        <Confirmed />
                    </Suspense>
                </Route>
                <Route path={PAGES.AUTH_MAGIC}>
                    <Suspense fallback={<Loader />}>
                        <AuthMagic />
                    </Suspense>
                </Route>
                <Route path={PAGES.CONFIRM_RECOVERY}>
                    <Suspense fallback={<Loader />}>
                        <ConfirmRecovery />
                    </Suspense>
                </Route>
                <Route path={PAGES.INVITATION}>
                    <Suspense fallback={<Loader />}>
                        <Invitation />
                    </Suspense>
                </Route>
                <Route path={PAGES.SUBSCRIPTIONS}>
                    <Suspense fallback={<Loader />}>
                        <Subscriptions />
                    </Suspense>
                </Route>
                <Route path={PAGES.PROFILE}>
                    <Suspense fallback={<Skeleton page={PAGES.PROFILE} useContainer />}>
                        <Profile />
                    </Suspense>
                </Route>
                <Route path={PAGES.MY_COMMUNITIES}>
                    <Suspense fallback={<Skeleton page={PAGES.MY_COMMUNITIES} useContainer />}>
                        <MyCommunities />
                    </Suspense>
                </Route>
                <Route path={PAGES.CONTACTS}>
                    <Suspense fallback={<Skeleton useContainer />}>
                        <ContactList />
                    </Suspense>
                </Route>
                <Route path={PAGES.PARTICIPANTS}>
                    <Suspense fallback={<Skeleton useContainer />}>
                        <Participants />
                    </Suspense>
                </Route>
                <Route path={PAGES.FULL_PARTICIPANT_VIEW}>
                    <Suspense fallback={<Skeleton page={PAGES.FULL_PARTICIPANT_VIEW} useContainer />}>
                        <FullParticipantView />
                    </Suspense>
                </Route>
                <Redirect to={PAGES.MY_COMMUNITIES} />
            </Switch>
        </Layout>

    )
};

export default Page;

import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { useLogin } from "../../context/community";
import { NavLink, Link } from "react-router-dom";
import { PAGES } from "../../routes/PAGES";
import LangService from "../../services/LangService";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import validation from "./validation";
import { useCommunity } from "../../context/community";
import { AUTH_TYPES } from "../../context/AUTH_TYPES";
import OAuthLogin from "./components/OAuthLogin";
import { useGoogleLogin } from "./GoogleLogin/useGoogleLogin";
import { GoogleLogin } from "./GoogleLogin/GoogleLogin";
import { useOneTimeCode } from "./OneTimeCode/useOneTimeCode";
import { ShortAuth } from "./ShortAuth/ShortAuth";
import cn from "classnames";

const Login = () => {
    const GTAG_EVENT = 'login'
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const { community: { authConfig, logoUrl, domain } } = useCommunity();
    const authMap = LangService.map.pages.Auth;
    const langMap = LangService.map.pages.login;
    const errorsMap = LangService.map.errors;
    const hasEmail = authConfig?.findIndex(conf => conf.authType === AUTH_TYPES.EMAIL) != -1;
    const { hasGoogle, handleGoogleSuccess, handleGoogleFailure } = useGoogleLogin({
        gtagEvent: GTAG_EVENT,
        setErrorText
    });
    const { hasCodeAuth } = useOneTimeCode();
    const hasOAuth2 = authConfig && authConfig.findIndex(conf => conf.authType === "OAUTH2") != -1;

    const handleError = () => {
        setErrorText("");
        const error = validation.email(email) || validation.password(password);
        if (error) {
            setErrorText(error);
        }

        return Boolean(error);
    };

    const loginSubmit = async () => {
        try {
            const authContext = {
                authType: AUTH_TYPES.EMAIL,
                email: email,
                password: password,
                token: null,
                backTo: null,
                introFor: null
            }
            await login(authContext);
        } catch (e) {
            if (e.msg === "Invalid login or password") {
                e.msg = errorsMap[1011];
            }
            if (e.msg === "Access Denied") {
                e.msg = errorsMap[1005];
            }
            setErrorText(e.msg);
        }
    };

    const handleSubmit = async () => {
        if (handleError()) {
            return;
        }
        setLoading(true);
        gtag("event", GTAG_EVENT, {
            method: "Email",
        });
        await loginSubmit();
        setLoading(false);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            return handleSubmit();
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.brandLogo}>
                    <NavLink to={PAGES.WELCOME}>
                        <img src={logoUrl} alt="brand" width="200" />
                    </NavLink>
                </div>
                {domain !== "connect" && (<>
                    <div className={styles.loginText}>{langMap.title}</div>
                    <div className={styles.loginSubText}>{langMap.subtitle}</div>
                </>)}
                {domain === "connect" && (<>
                    <br />
                    <br />
                    <div className={styles.loginSubText}>{langMap.title}</div>
                </>)}
                {hasGoogle && !hasCodeAuth && (
                    <GoogleLogin
                        onGoogleSuccess={handleGoogleSuccess}
                        onGoogleFailure={handleGoogleFailure}
                    />
                )}
                {hasCodeAuth && (
                    <ShortAuth gtagEvent={GTAG_EVENT} />
                )}
                {hasOAuth2 && <OAuthLogin config={authConfig} />}
                {errorText && <div className={styles.errorText}>{errorText}</div>}
                {hasEmail && <>
                    {authConfig?.length > 1 &&
                        <div className={styles.orContinueWith}>
                            <span>{authMap.orContinueWith}</span>
                        </div>}
                    <Input
                        label={authMap.email}
                        type="email"
                        value={email}
                        onChange={setEmail}
                        onKeyDown={handleKeyDown}
                        name="email"
                        autoComplete="email"
                        required
                        autoFocus
                    />
                    <Input
                        label={authMap.password}
                        type="password"
                        value={password}
                        onChange={setPassword}
                        onKeyDown={handleKeyDown}
                        name="password"
                        autoComplete="current-password"
                        required
                    />
                    <Button
                        wide
                        type="button"
                        onClick={handleSubmit}
                        style={styles.submitButton}
                        loading={loading}
                        active
                    >
                        {langMap.button}
                    </Button>
                </>}

                <p className={cn(styles.bottomQuestion, {
                    [styles.shortAuth]: hasCodeAuth
                })}>
                    {langMap.accountQuestion}
                    <br />
                    {domain !== "connect" && (
                        <Link to={PAGES.REGISTRATION} className={styles.link}>
                            {langMap.link}
                        </Link>
                    )}
                    {domain === "connect" && (
                        <a href={"https://my.entire.vc/apply-connect/"} className={styles.link}>
                            Apply now
                        </a>
                    )}
                </p>
                {hasEmail &&
                    (<p className={styles.bottomQuestion}>
                        {langMap.recoveryQuestion}
                        <Link to={PAGES.RECOVERY} className={styles.link}>
                            {langMap.recovery}
                        </Link>
                    </p>)}
            </div>
        </div>
    );
};

export default Login;

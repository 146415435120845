import React, { useEffect, useState } from 'react';
import styles from './LandingHeader.module.scss';
import MiniLogo from 'components/Logo/MiniLogo';
import { useHistory, NavLink } from 'react-router-dom';
import { useWindowDimensions } from 'context/window';
import { PAGES } from 'routes/PAGES';
import { ReactComponent as MenuIcon } from "assets/Imgs/menu.svg";
import Button from 'components/Button/Button';

const LandingHeader = () => {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setIsMenuOpen(width > 767);
    }, [width]);

    const toggleMenu = (e) => {
        if (e.target.tagName === 'NAV' && width < 768) {
            setIsMenuOpen(false);
        }
    };

    return (
        <header className={styles.landingHeader}>
            <div className={styles.wrapper}>
                <NavLink to={PAGES.WELCOME} onClick={() => {
                    gtag('event', 'header_logo_click')
                }}>
                    <MiniLogo className={styles.logo} />
                </NavLink>
                <Button
                    onClick={() => setIsMenuOpen(true)}
                    style={styles.menu}
                    icon={<MenuIcon />}
                    iconOnly
                />
                {isMenuOpen && <nav onClick={toggleMenu}>
                    <div className={styles.sections}>
                        <section className={styles.navLinks}>
                            <ul>
                                <li>
                                    <NavLink to={PAGES.REGISTRATION} onClick={() => {
                                        gtag('event', 'header_about_click')
                                    }}>
                                        About
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={PAGES.REGISTRATION} onClick={() => {
                                        gtag('event', 'header_members_click')
                                    }}>
                                        Members
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={PAGES.REGISTRATION} onClick={() => {
                                        gtag('event', 'header_experts_click')
                                    }}>
                                        Experts
                                    </NavLink>
                                </li>
                            </ul>
                        </section>
                        <section className={styles.authLinks}>
                            <a
                                role='button'
                                className={styles.closeMenu}
                                onClick={() => {
                                    setIsMenuOpen(false);
                                }}>
                                Cancel
                            </a>
                            <NavLink to={PAGES.LOGIN} onClick={() => {
                                gtag('event', 'header_login_click')
                            }}>
                                Log In
                            </NavLink>
                            <Button
                                style={styles.signUp}
                                to={PAGES.REGISTRATION}
                                onClick={() => {
                                    gtag('event', 'header_signup_click');
                                    history.push(PAGES.REGISTRATION);
                                }}
                            >
                                Sign Up
                            </Button>
                        </section>
                    </div>
                </nav>}
            </div>
        </header>
    );
};

export default LandingHeader;

import React, { Suspense, lazy } from "react";
import styles from "./Layout.module.scss";
import Header from "../Header/Header";
import { useCommunity } from "../../context/community";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { PAGES } from "routes/PAGES";
import { getToken } from "services/auth";

const Footer = lazy(() => import('../Footer/Footer'));
const GoUpButton = lazy(() => import('../GoUpButton/GoUpButton'))

const Layout = ({ children }) => {
    const history = useHistory();
    const { community: { domain } } = useCommunity();

    const isLanding = history.location.pathname === PAGES.WELCOME;


    if (domain === "connect" && isLanding && !getToken()) {
        window.location.replace('https://my.entire.vc/connect/');
        return null;
    }

    return (
        <div className={cn(styles.wrapper, {
            [styles.landing]: isLanding
        })} data-community={domain}>
            <Header />
            <main className={styles.main}>
                {children}
            </main>
            <Suspense fallback={<div></div>}>
                <Footer />
                <GoUpButton />
            </Suspense>
        </div>
    )
}

export default Layout
